































































































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";

import {
    AnalyticsVisitorWithHits,
    AnalyticsConversation,
    AnalyticsMessageDay
} from "@/types/interfaces";

export default Vue.extend({
    components: {
        Spinners,
        AnalyticsDateControl
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        messagesCsvUrl(): string {
            return this.getMessagesCsvUrl();
        },

        messages(): Array<AnalyticsVisitorWithHits> {
            return analyticsStore.messages;
        },

        // start here
        messagesTableHeaders(): Array<string> {
            return [
                "Attendee ID",
                "First Name",
                "Last Name",
                "Email",
                "Company Name",
                "Total Messages"
            ];
        },

        messagesFlatData(): Array<Array<string>> {
            return this.messages.map((msg) => {
                return [
                    msg.uid,
                    msg.firstName,
                    msg.lastName,
                    msg.email,
                    msg.companyName,
                    `${msg.hits ? msg.hits : 0}`
                ];
            });
        },

        messagesTableFooter(): Array<string> {
            return [
                "",
                "Total Unique Users",
                `${this.totalUsers}`,
                "",
                "Total Messages",
                `${this.totalMessages}`
            ];
        },

        totalMessages(): number {
            return this.messages.reduce((acc, curr) => {
                return acc + curr.hits;
            }, 0);
        },

        totalUsers(): number {
            return this.messages.length;
        },

        conversations(): Array<AnalyticsConversation> {
            return analyticsStore.conversations;
        },

        totalConversations(): number {
            return this.conversations.length;
        },

        conversationsWithMultipleParticipants(): number {
            return this.conversations.filter((conv) => conv.uniqueUsers > 1)
                .length;
        },

        multipleParticipantsPercent(): string {
            return (
                (this.conversationsWithMultipleParticipants /
                    this.totalConversations) *
                100
            ).toFixed(2);
        },

        messagesByDate(): Array<AnalyticsMessageDay> {
            return analyticsStore.messagesByDate;
        },

        messagesByDateHeader(): Array<string> {
            return ["Date", "Number of Messages"];
        },

        messagesByDateFlatData(): Array<Array<string>> {
            return this.messagesByDate
                .filter((date) => date.messages > 0)
                .map((date) => {
                    return [date.date, `${date.messages}`];
                });
        },

        messagesByDateCsvUrl(): string {
            return this.getMessagesByDateCsvUrl();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;

            await analyticsStore.getMessageData();

            this.loading = false;
        },

        getMessagesCsvUrl(): string {
            const dataArray = [
                this.messagesTableHeaders,
                ...this.messagesFlatData,
                this.messagesTableFooter
            ];

            return transformDataToCsvUrl(dataArray);
        },

        getMessagesByDateCsvUrl(): string {
            const dataArray = [
                this.messagesByDateHeader,
                ...this.messagesByDateFlatData
            ];

            return transformDataToCsvUrl(dataArray);
        }
    }
});
