















import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";

import { format } from "date-fns";

export default Vue.extend ({
    data() {
        return {
            selectedDates: [] as Array<Date>
        }
    },

    computed:{
        hasDates(): boolean {
            return this.selectedDates.length > 0;
        }
    },
    methods: {
        clearDates() {
            this.selectedDates.splice(0, 2);
            this.updateDates("", "");
        },
    
        async updateDates(start: string, end: string) {
            await analyticsStore.updateReportDates({
                start: start,
                end: end
            });
            this.$emit("dateUpdated");
        }
    },
    watch: {
        selectedDates(newDates: Array<Date>, oldDates: Array<Date>) {
            const formatStr = "yyyy-MM-dd";
            const newStart =
                newDates.length > 1 ? format(newDates[0], formatStr) : "";
            const newEnd =
                newDates.length > 1 ? format(newDates[1], formatStr) : "";
            const oldStart =
                oldDates.length > 1 ? format(oldDates[0], formatStr) : "";
            const oldEnd =
                oldDates.length > 1 ? format(oldDates[1], formatStr) : "";
    
            if (newStart != oldStart || newEnd != oldEnd) {
                this.updateDates(newStart, newEnd);
            }
        }
            // @Watch("selectedDates")
            // updateStore(newDates: Array<Date>, oldDates: Array<Date>) {
            //     const formatStr = "yyyy-MM-dd";
            //     const newStart =
            //         newDates.length > 1 ? format(newDates[0], formatStr) : "";
            //     const newEnd =
            //         newDates.length > 1 ? format(newDates[1], formatStr) : "";
            //     const oldStart =
            //         oldDates.length > 1 ? format(oldDates[0], formatStr) : "";
            //     const oldEnd =
            //         oldDates.length > 1 ? format(oldDates[1], formatStr) : "";
        
            //     if (newStart != oldStart || newEnd != oldEnd) {
            //         this.updateDates(newStart, newEnd);
            //     }
            // }
    }
})
