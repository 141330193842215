/**
 * Transforms a data array into a Blob URL for a CSV.
 *
 * @param table Array<Array<string>> A two-level array of strings to be transformed into CSV output.
 *
 * @returns string A URL for a Blob containing the file.
 */
export const transformDataToCsvUrl = function(
    table: Array<Array<string | number>>
) {
    const dataArray = table.map((row) => {
        const escapedCells = [] as Array<string>;

        row.forEach((cell) => {
            const cellString = cell ? `${cell}` : "";
            escapedCells.push(`"${cellString.replaceAll('"', "'")}"`);
        });

        return escapedCells.join(",") + "\n";
    });

    const blob = new Blob(dataArray, { type: "text/csv" });

    return window.URL.createObjectURL(blob);
};
